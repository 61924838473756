import { get, post, put } from "@/api/index";
// 根据md5获取DWG的mapId
export function getMapId(id) {
  return get("/dwg/convert/public/" + id);
}
// 上传DWG文件
export function uploadDwg(data) {
  return post("/dwg/convert/upload", data);
}
// 上传文件
export function postFile(data) {
  return post("/api/file/upload", data);
}
// 新增cad工程
export function onCarProject(data) {
  return post(window.configData.VUE_APP_BASEURL + "/cad/project/import", data);
}
// 新增cad工程
export function postCarProject(data) {
  return post(window.configData.VUE_APP_BASEURL + "/cad/project", data);
}
// 编辑cad工程
export function editCarProject(data) {
  return post(window.configData.VUE_APP_BASEURL + "/cad/project/update", data);
}
