// import { dialogMixin } from "@/utils/dialogMixin";
// mixins: [dialogMixin],
// @close="closeDialog"
export const dialogMixin = {
  methods: {
    closeDialog() {
      let refsDeom = this.$refs;
      for (const key in refsDeom) {
        if (refsDeom[key]) {
          if (refsDeom[key].length > 0) {
            refsDeom[key].map(ele => {
              if (ele.blur) ele.blur();
            });
          } else if (
            key == "ztys" &&
            refsDeom[key].$children &&
            refsDeom[key].$children.length > 0
          ) {
            refsDeom[key].$children.map(ele => {
              if (ele.blur) ele.blur();
            });
          } else refsDeom[key].blur && refsDeom[key].blur();
        }
      }
    }
  }
};
